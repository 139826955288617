body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-x: hidden;
}
/* src/Commitment.css */

.commitment-container {
  padding: 2rem;
  /* background-color: #f9f9f9; */
  /* min-height: 100vh; */
}

.dark-mode .commitment-content {
  max-width: 800px;
  margin: 0 auto;
  background: #1d2f40;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.light-mode .commitment-content {
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dark-mode .commitment-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #ffffff;
}
.light-mode .commitment-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #333;
}

.dark-mode .last-updated {
  font-size: 0.9rem;
  color: #ffffff;
  margin-bottom: 1.5rem;
}
.light-mode .last-updated {
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 1.5rem;
}

.dark-mode .commitment-text {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #ffffff;
  margin-bottom: 1rem;
}
.light-mode .commitment-text {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1rem;
}

.commitment-list {
  margin: 1.5rem 0;
  padding-left: 1.5rem;
}

.dark-mode .commitment-list li {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  color: #ffffff;
}
.light-mode .commitment-list li {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  color: #333;
}
/* src/ReturnsPolicy.css */

.returns-policy-container {
  padding: 2rem;
  /* min-height: 100vh; */
}

.dark-mode .returns-policy-content {
  max-width: 800px;
  margin: 0 auto;
  background: #1d2f40;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.light-mode .returns-policy-content {
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dark-mode .returns-policy-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #ffffff;
}
.light-mode .returns-policy-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #333;
}

.dark-mode .last-updated {
  font-size: 0.9rem;
  color: #ffffff;
  margin-bottom: 1.5rem;
}
.light-mode .last-updated {
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 1.5rem;
}

.dark-mode .returns-policy-text {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #ffffff;
  margin-bottom: 1rem;
}
.light-mode .returns-policy-text {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1rem;
}

.returns-policy-list {
  margin: 1.5rem 0;
  padding-left: 1.5rem;
}

.dark-mode .returns-policy-list li {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  color: #ffffff;
}
.light-mode .returns-policy-list li {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  color: #333;
}
/*AcceptableUsePolicy.css */

.dark-mode .acceptable-use-policy-container {
  max-width: 800px;
  margin: 0 auto;
  background: #1d2f40;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.light-mode .acceptable-use-policy-content {
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.acceptable-use-policy-title {
  font-size: 2em;
  margin-bottom: 20px;
}

.acceptable-use-policy-subtitle {
  font-size: 1.5em;
  margin-top: 20px;
}

.acceptable-use-policy-text {
  font-size: 1em;
  margin: 10px 0;
}

.acceptable-use-policy-list {
  padding-left: 20px;
}

.last-updated {
  font-style: italic;
  margin-top: 20px;
}
/* privacypolicy.js */
.dark-mode .privacy-policy-container {
  max-width: 800px;
  margin: 0 auto;
  background: #1d2f40;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
/* cookiespoliceis.css*/
.dark-mode .cookies-policies-container {
  max-width: 800px;
  margin: 0 auto;
  background: #1d2f40;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
/* termofservice.css */
.terms-of-service-container {
  padding: 2rem;
  /* min-height: 100vh; */
}

/* Dark mode styles */
.dark-mode .terms-of-service-content {
  max-width: 800px;
  margin: 0 auto;
  background: #1d2f40;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dark-mode .terms-of-service-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #ffffff;
}

.dark-mode .last-updated {
  font-size: 0.9rem;
  color: #ffffff;
  margin-bottom: 1.5rem;
}

.dark-mode .terms-of-service-text {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #ffffff;
  margin-bottom: 1rem;
}

.dark-mode .terms-of-service-list li {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  color: #ffffff;
}

/* Light mode styles */
.light-mode .terms-of-service-content {
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.light-mode .terms-of-service-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #333;
}

.light-mode .last-updated {
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 1.5rem;
}

.light-mode .terms-of-service-text {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1rem;
}

.light-mode .terms-of-service-list li {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  color: #333;
}

.navbar-container {
  padding-top: 70px;
  padding-top: 5em; /* Adjust this value based on your Navbar height */
}
.sticky-navbar {
  position: fixed !important;
  top: 0;
  z-index: 1000;
  background-color: inherit;
}

.dark-mode .select-field{
  background-color: #b89812 !important;
  color: white !important;
}
.light-mode .select-field{
  background-color: black !important;
  color: white !important;
}
.select-field{
  display: block !important;
    width: 100% !important;
    padding: .375rem .75rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: var(--bs-body-color) !important;
    appearance: none !important;
    
    background-clip: padding-box !important;
    border: black !important;
    
    border-radius: var(--bs-border-radius) !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}
.light-mode .card{
  background-color: white;
}
.dark-mode .card{
  background-color: #fffafa !important;
  border: #1d2f40 !important;
  color: #0a0707 !important;
}
.filtericons {
  color: #000;
  top: 5px;
  right: 192px;
}
.video-card-wrapper {
  display: flex;
  flex-direction: column;

}

.video-card {
  overflow: hidden;
  transition: transform 0.2s;
  margin-bottom: 8px;
}


.video-thumbnail {
  position: relative;
}

.video-thumbnail img {
  border-radius: 10px;
  width: 100%;
  height: auto;
}

.video-duration {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
}

.video-info {
  padding: 8px 0;
}

.creator-img {
  width: 25px;
  border-radius: 50%;
}

.video-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
}

.video-uploader {
  font-size: 14px;
  color: #666;
}

.video-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
}

.add-to-favourite {
  cursor: pointer;
}

.watch-now-btn {
  background-color: #ff0000;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.watch-now-btn:hover {
  background-color: #e60000;
}

.icon {
  font-size: 24px;
}

.favourite {
  color: #ff0000;
}

/* ShortVideos.css */
.play-pause-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3em;
  color: #fff;
}

.play-pause-icon:hover {
  opacity: 0.8;
}

.play-pause-icon.play {
  display: none;
}

.play-pause-icon.pause {
  display: block;
}
.short-videos-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: #fff;
}

.short-video {
  position: relative;
  width: 396px;
  height: 706px;
  margin: 20px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
  background-color: #000;
}

.video-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.video-info {
  bottom: 10px;
  left: 10px;
  right: 10px;
  color: white;
  z-index: 1;
  text-align: left;
}

.video-info .uploader {
  margin: 0;
  font-size: 20px;
}

.video-info .title {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.video-actions {
  position: absolute;
  right: 10px;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

.short-icon {
  cursor: pointer;
  font-size: 1.5em;
  margin: 10px 0;
  background-color: #d5c2c2;
  padding: 10px;
  border-radius: 50px;
  font-size: 40px;
}
.short-liked {
  color: #e3b32a !important;
}

.short-icon:hover {
  color: #e3b32a;
}

.light-mode .iconcolor {
  color: #007bff;
}
.dark-mode .folder {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
.light-mode .folder {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.dark-mode .folder:hover {
  background-color: #ecc870;
}
.light-mode .folder:hover {
  background-color: #007bff;
}

.dark-mode .folder.active {
  background-color: #ecc870;
}
.light-mode .folder.active {
  background-color: #007bff;
}

.css-i4bv87-MuiSvgIcon-root {
  color: white !important;
}
.dark-mode .reporttext {
  color: white;
}
.light-mode .reporttext {
  color: white;
}
.dragonsbtn1 {
  display: inline-block;
  text-align: center;
  margin-bottom: 5px;
}

.dragonsbtn1.active {
  background-color: #007bff;
  color: white; 
}
.dragonsbtn1.blur {
  opacity: 0.5;

}
.aboutreport {
  background: linear-gradient(
    270.3deg,
    #1d2f40 0.24%,
    #2c3e50 99.74%
  ) !important;
}

.dragonsbtn1.active {
  font-weight: bold;
  border-bottom: 2px solid #000;
}

.dragonsbtn1 {
  background: linear-gradient(
    270.3deg,
    #1d2f40 0.24%,
    #2c3e50 99.74%
  ) !important;
}
.dark-mode .dragonsbtn1:hover {
  background: #ecc870 !important;
  text-decoration: none;
  color: #ffffff !important;
  font-size: 15px;
}
.light-mode .dragonsbtn1:hover {
  background: #007bff !important;
  text-decoration: none;
  color: #ffffff !important;
  font-size: 15px;
}
.light-mode .dragonsbtn1 {
  background: linear-gradient(
    270.3deg,
    #1d2f40 0.24%,
    #2c3e50 99.74%
  ) !important;
  color: white !important;
}
.light-mode .dragonsbtn {
  background: #0d1824;
  text-decoration: none;
  color: #ffffff !important;
  font-size: 15px;
}

.light-mode .dragonsbtn:hover {
  background: #3498db !important;
  text-decoration: none;
  color: #ffffff !important;
  font-size: 15px;
}

.dark-mode .dragonsbtn {
  background: #0d1824;
  text-decoration: none;
  color: #ffffff;
  font-size: 15px;
}
.repostcontent {
  background: linear-gradient(
    270.3deg,
    #1d2f40 0.24%,
    #2c3e50 99.74%
  ) !important;
  border: 1px solid white;
  cursor: pointer;
  align-items: center;
  justify-content: space-around;
}
.modal-header {
  border: 1px solid white;
  background: linear-gradient(0deg, #0d1824, #0d1824),
    linear-gradient(90deg, #10181f 0.82%, #3498db 48.01%, #10181f 98.79%) !important;
  color: white;
}
.btn-close {
  color: white !important;
}
.modal-body {
  padding: 30px !important;
  border: 1px solid white;
  background: linear-gradient(0deg, #0d1824, #0d1824),
    linear-gradient(90deg, #10181f 0.82%, #3498db 48.01%, #10181f 98.79%) !important;

  color: white;
}
.modal-footer {
  border: 1px solid white;
  background: linear-gradient(0deg, #0d1824, #0d1824),
    linear-gradient(90deg, #10181f 0.82%, #3498db 48.01%, #10181f 98.79%) !important;
}
.checkbox-container {
  display: flex;
  flex-direction: column; 
}

.checkbox-container .MuiFormControlLabel-root {
  margin-bottom: 8px;
}

.longbanner {
  border: 1px solid black;
}
.light-mode .content {
  color: #000;
}
.light-mode .blue {
  color: #3498db;
}
.dark-mode .blue {
  color: #e2b912;
}
.video-duration-card {
  position: absolute;
  top: 156px;
  left: 10px;
}

.light-mode .video-duration {
  background: #000;
  border-radius: 3px;
  padding: 0px 2px 2px 2px;
  justify-content: center;
}

.dark-mode .video-duration {
  background: #000;
  border-radius: 3px;
  padding: 0px 2px 2px 2px;
  justify-content: center;
}

.dark-mode .content {
  color: #ffffff;
}

.videoicon {
  width: 15px;
}

.light-mode .premiumcard {
  color: white;
}

.dark-mode .premiumcard {
  color: white;
}

.light-mode .yellow {
  color: #ffffff;
}

.light-mode {
  background: #fff;
}

.dark-mode {
  background: #232a2c;
}

.dark-mode .backgruondcolor {
  background: linear-gradient(180deg, #2c3e50 99.99%, rgba(44, 62, 80, 0) 100%);
}

.light-mode .backgruondcolor {
  background: linear-gradient(180deg, #2c3e50 99.99%, rgba(44, 62, 80, 0) 100%);
  color: #000;
}

.dark-mode .form-control {
  background-color: #1d2f40 !important;
  border: #1d2f40 !important;
  color: #ffffff !important;
}

.form-control {
  background-color: #fff !important;
  border: #1d2f40 !important;
  color: black !important;
}


.dark-mode .category {
  border-bottom: 2px solid #e2b912;
  color: #ffffff;
}

.category {
  border-bottom: 2px solid #e2b912;
  color: black;
}

.btn-Viewmore {
  border: 1px solid #2c3e50;
  background-color: #2c3e50;
  color: white;
}

/* dark  */
.mode-toggle-btn {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.light-mode {
  background-color: #f0f0f0;
  color: #000000;
}

.dark-mode {
  background-color: #121212;
  color: #ffffff;
  
}

/* end */
.suggestion-box {
  background: #e0f1eb;
  width: 377px;
  position: absolute;
  overflow: auto;
  max-height: 200px;
}

.suggestion-box .suggestion-item {
  color: black;
  text-align: left;
  padding: 6px;
  margin: 0;
  border-bottom: 1px solid black;
  cursor: pointer;
}

/* font Families */
.font-inter {
  font-family: "Inter", sans-serif;
}

.font-lato {
  font-family: "Lato", sans-serif;
}

/* Navbar */
.logo {
  height: 65px;
}

.video-card {
  position: relative;
}

.video-card:hover .watch-now-btn {
  display: block;
  transition: 0.5;
}

.video-card:hover img {
  filter: brightness(0.8);
}

.watch-now-btn {
  display: none;
  background: transparent;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 35%;
  height: 40px;
  border: solid 1px white;
  color: white;
}

.second-nav-drop {
  z-index: 999;
  background-color: #1d2f40;
  box-shadow: 0px 4px 4px 0px #0000001f;
  width: 190px;
  border-radius: 8px;
}

.second-nav-drop li:hover {
  background-color: #e2b912;
  color: white !important;
}

.second-nav-drop a.active li {
  background: #e2b912 !important;
  color: white !important;
}

.second-nav-drop li:hover a {
  color: white !important;
}

.second-nav-drop li a {
  color: #333333 !important;
}

.second-nav-drop li {
  padding: 5px 12px !important;
  margin-top: 4px;
  border-radius: 6px;
  list-style: none;
  font-weight: 500;
  color: #ffffff !important;
}

.no-list-style::before {
  border: 15px solid transparent;
  border-bottom-color: #f5f5ff;
  position: absolute;
  content: "";
  left: 70px;
  top: -26px;
}

.dark-mode .activeTab {
  background-color: #e2b912 !important;
}

.light-mode .activeTab {
  background-color: #3498db !important;
}

.no-list-style {
  right: 0%;
  z-index: 999;
  background-color: #1d2f40;
  box-shadow: 0px 4px 4px 0px #0000001f;
  width: 190px;
  border-radius: 8px;
}

.light-mode .no-list-style li:hover {
  background-color: #3498db;
  color: white !important;
}

.no-list-style li:hover {
  background-color: #ffde59;
  color: #000 !important;
}

.light-mode .no-list-style a.active li {
  background: #3498db !important;

  color: white !important;
}
.dark-mode .no-list-style a.active li {
  background: #e2b912 !important;

  color: white !important;
}

.no-list-style li:hover a {
  color: white !important;
}

.no-list-style li a {
  color: #333333 !important;
}

.no-list-style li {
  padding: 5px 12px !important;
  margin-top: 4px;
  border-radius: 6px;
  list-style: none;
  font-weight: 500;

  color: #ffffff !important;
}

.light-mode a {
  color: #000000 !important;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
}

.dark-mode a {
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
}

.light-mode a.active {
  color: #3498db !important;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
}

a.active {
  color: #e2b912 !important;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
}

.light-mode .navbar-toggler {
  background: #3498db;
}

.dark-mode .navbar-toggler {
  background: #e6ac31;
}

.light-mode .arrowbg {
  color: #ffffff;
}

.dark-mode .arrowbg {
  color: black;
}

.dark-mode .navbar-nav a {
  color: #ffffff !important;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  width: fit-content;
}

.light-mode .navbar-nav a {
  color: #ffffff !important;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  width: fit-content;
}

.light-mode .navbar-nav a.active {
  color: #3498db !important;
  font-weight: 700;
  font-size: 14px;
  border-bottom: 2px solid;
  width: fit-content;
}

.dark-mode .navbar-nav a.active {
  color: #e2b912 !important;
  font-weight: 700;
  font-size: 14px;
  border-bottom: 2px solid;
  width: fit-content;
}

.notifaction-dropdon {
  border-radius: 12px !important;
}

.tabs-overflow {
  overflow-x: scroll;
}

.tabs-scrollbar ::-webkit-scrollbar {
  width: 2px !important;
  height: 2px;
}

.tabs-scrollbar ::-webkit-scrollbar-track {
  background-color: #e4e4e4 !important;
  border-radius: 2px;
}

.notifaction-dropdon ::-webkit-scrollbar {
  width: 10px !important;
  height: 10px;
}

.notifaction-dropdon ::-webkit-scrollbar-track {
  background-color: #e4e4e4 !important;
  border-radius: 10px;
}

.notifaction-dropdon ::-webkit-scrollbar-thumb {
  background: #a8a8a8 !important;
}

.notifaction-dropdon ::-webkit-scrollbar-thumb:hover {
  background: #b30000 !important;
}

.notifaction-dropdon li a {
  background-color: transparent !important;
  white-space: break-spaces !important;
}

.notifaction-dropdon li a:hover {
  color: white;
  border-radius: 4px;
}

.notifaction-dropdon .dropdown-menu {
  --bs-dropdown-min-width: 15rem !important;
  margin-top: 25px !important;
  height: 200px;
  border: none;
  background: #232a2c;
  width: 281px;
  overflow-y: scroll;
}

.light-mode .navbtn {
  background: #3498db;
  border: 1px solid #3498db;
  color: white;
}
.dark-mode .navbtn {
  background: linear-gradient(270.3deg, #ff914d 0.24%, #ffde59 99.74%);
  box-shadow: 10px 0px 40px 0px #3498db33;
  border: 1px solid #e2b912;
}

/* Add to Cart Icon Styles */
.cart-icon {
  position: relative;
  display: inline-block;
}

.cart-icon img {
  height: auto;
  cursor: pointer;
}

.cart-notification {
  position: absolute;
  top: -15px;
  right: -5px;
  background-color: #e2b912;
  color: black;
  border-radius: 20%;
  padding: 3px 5px;
  font-size: 8px;
}

/* Navbar End */
/* Css Font-sizes */
.font-14 {
  font-size: 20px;
}

.font-15 {
  font-size: 15px;
}

.font-40 {
  font-size: 40px;
}

.font-18 {
  font-size: 18px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-10 {
  font-size: 10px;
}

.font-9 {
  font-size: 9px;
}

.font-8 {
  font-size: 8px;
}

.font-20 {
  font-size: 20px;
}

.font-75 {
  font-size: 75px;
}

.font-25 {
  font-size: 25px;
}

.font-35 {
  font-size: 35px;
}

.font-22 {
  font-size: 22px;
}

.font-29 {
  font-size: 29px;
}

.font-50 {
  font-size: 50px;
}

.font-70 {
  font-size: 70px;
}

.font-30 {
  font-size: 30px;
}

.font-60 {
  font-size: 60px;
}

/* Css Fonts sizes End */

/* Css Font Weight  */
.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

/* Home Page */
.bgimage {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
  color: white;
  opacity: 0.8;
}

.light-mode .input-group-text {
  background-color: #3498db !important;
  border: 1px solid #3498db !important;
  color: #fff !important;
}

.dark-mode .input-group-text {
  background-color: #e2b912 !important;
  border: 1px solid #e2b912 !important;
  color: #0d1824 !important;
}

.light-mode .input-fieldblack {
  background-color: #f5f5f5 !important;
}

.dark-mode .input-fieldblack {
  background-color: #2c3e50 !important;
}

.form-control::placeholder {
  color: #d5c2c2 !important;
}

.input-fieldblack::placeholder {
  background-color: transparent !important;
  color: #ffffff !important;
}

.btn-primary {
  background-color: #ecc870 !important;
  color: #0d1824 !important;
  --bs-btn-border-color: #ecc870 !important;
}



.shop-btn {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      180deg,
      rgba(44, 62, 80, 0.8) 0%,
      rgba(44, 62, 80, 0.8) 100%
    );
  border: none;

  color: #ffffff;
}

.addtocard {
  padding: 6px;
  top: 5px;
  right: 5px;
  position: absolute;
}

.addtocart {
  box-shadow: 10px 0px 40px 0px #3498db33;
  cursor: pointer;
}

.cardcontent {
  position: absolute;
  bottom: 0px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 96%;
}

/* Footer.css */
.light-mode .linenew1 {
  border-bottom: 4px solid #3498db;
}

.dark-mode .linenew1 {
  border-bottom: 4px solid #e2b912;
}

.info-list {
  list-style: none;
  padding: 0;
}

.info-list li {
  margin-bottom: 10px;
}

.dark-mode.info-list a {
  text-decoration: none;
  color: #e2b912;
}

.light-mode .info-list a {
  text-decoration: none;
  color: #fff !important;
}

.light-mode .circle-icon {
  width: 5px;
  margin-right: 5px;
  color: #3498db;
}

.circle-icon {
  width: 5px;
  margin-right: 5px;
  color: #e2b912;
}

.light-mode .bg-footer {
  background: #2c3e50;
  color: #ffffff;
  border-top: 8px solid #1d2f40;
}

.dark-mode .bg-footer {
  background: #1d2f40;
  color: #ffffff;
  border-top: 8px solid #1d2f40;
}

.light-mode .Connectline {
  border-top: 1px solid #ffffff;
}

.Connectline {
  border-top: 1px solid #263645;
}

.light-mode.Copyrightline {
  border-top: 2px solid #f5f5f5;
  background-color: #fff;
}

.dark-mode.Copyrightline {
  border-top: 2px solid #f5f5f5;
  background-color: #263645;
}

.fotterline {
  border-bottom: 1px solid #263645;
}

.custom-youtube-icon {
  width: 40px;
  border-radius: 20px;
  background: #cd201f;
}

.fotterbottom {
  border-bottom: 1px solid #f5f5f5;
}

.fotterimg {
  width: 120px;
  height: 62px;
}

/* Video detail page css */
.dropzone-custom {
  background-size: contain;

  background-repeat: no-repeat;
  border: 2px dotted;
  background-position: center;
}
.bg-maineimg {
  background-image: url(assetr/videodetail.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 60vh;
}

.premiumbtn {
  color: #f5f5f5;
  padding: 0px 6px;
  border-radius: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: #ce1e1e;

  border: 1px solid #ce1e1e;
}

.premiumbtn1 {
  color: #f5f5f5;
  padding: 0px 6px;
  border-radius: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: linear-gradient(0deg, #0d6efd, #0d6efd),
    linear-gradient(0deg, #3498db, #3498db);
  border: 1px solid #0d6efd;
}

.forpremiumuser {
  background: #1b1b1bb2;
}

.dragonscard {
  background: #2a3a4a;
  box-shadow: 0px 0px 40px 0px #5e5c9a1f;
  border-radius: 5px;
}

.iconscard {
  background: linear-gradient(0deg, #0d1824, #0d1824),
    linear-gradient(90deg, #10181f 0.82%, #3498db 48.01%, #10181f 98.79%);
}

.light-mode .icon-container {
  width: 40px;
  height: 40px;
  background: white;
  border: 1px solid #21252917;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.dark-mode .icon-container {
  width: 40px;
  height: 40px;
  background: linear-gradient(0deg, #1d2f40, #1d2f40),
    linear-gradient(0deg, rgba(33, 37, 41, 0.09), rgba(33, 37, 41, 0.09));
  border: 1px solid #21252917;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ecc870;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.cart {
  bottom: 33px;
  right: 0px;
  background-color: #ecc870;
  color: black;
  border-radius: 20%;
  padding: 3px 5px;
  font-size: 8px;
}

.cart2 {
  bottom: 33px;
  right: 0px;
  background-color: #ecc870;
  color: black;
  border-radius: 20%;
  padding: 3px 5px;
  font-size: 8px;
}

.arowicon {
  text-align: center;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;

}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #2c3e50;
 
}

.dragonsbtn:hover {
  background-color: #e2b912;
}

.dragoncard2 {
  background: #2a3a4a;
  box-shadow: 0px 0px 40px 0px #5e5c9a1f;
  border-radius: 5px;
}

.dragonscard4 {
  background: #2a3a4a;
  box-shadow: 0px 0px 40px 0px #5e5c9a1f;
  border-radius: 5px;
}

.electricicon {
  background: #ecc870;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  color: #0d1824;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark-mode .republictextwhite {
  color: #ffffff;
}

.light-mode .republictextwhite {
  color: rgb(255, 255, 255);
}

.dark-mode .video-title {
  color: #ffffff;
}

.light-mode .video-title {
  color: #000;
}

.bottomline {
  border-bottom: 1px solid #2c3e50;
}

.related-video {
  padding: 6px;
  top: 11px;
  right: 11px;
  position: absolute;
}

.dragonscard3 {
  background: #ffffff;
  box-shadow: 0px 0px 40px 0px #5e5c9a1f;
  border-radius: 5px;
}
.nav-pills .nav-item {
  margin-right: 1px;
}

.nav-pills .nav-link {
  color: white;
  background-color: #2c3e50;
}

.form-select:focus {
  border-color: #e2b912 !important;
  outline: 0;
  box-shadow: none !important;
}

.pricing-toggle {
  background-color: #1d2f40;
  border-radius: 30px;
  display: inline-block;
  border: 1px solid #f5f5f5;
  font-family: "Inter", sans-serif;
  font-size: 12px;
}

.fourtyoff {
  width: 100px;
}

.pricing-toggle [name="pricing"] {
  display: none;
}

.pricing-toggle input[type="radio"] + label {
  background-color: #1d2f40;
  color: #e2b912;
  padding: 5px;
  border-radius: 30px;
  cursor: pointer;
  user-select: none;
}

.pricing-toggle input[type="radio"]:checked + label {
  background-color: #e2b912;
  color: #0d1824;
}

.savebtn {
  color: #f5f5f5;
  padding: 5px;
  border-radius: 30px;
  cursor: pointer;
  user-select: none;
  background-color: #e2b912;
}

.light-mode .pricingannualcard {
  background: white;
  border: 1px solid #ffffff;
  border-radius: 17px;
  color: black;
}

.dark-mode .pricingannualcard {
  background: linear-gradient(0deg, #1d2f40, #1d2f40),
    linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #ffffff;
  border-radius: 17px;
  color: #f5f5f5;
}

.signupbtn {
  background-color: transparent;
  color: white;
  border: 1px solid #ffffff;
  border-radius: 7px;
}

.light-mode .getstarted {
  background: #3498db;

  color: #ffffff;
  border: 1px solid #3498db;
  border-radius: 7px;
}

.dark-mode .getstarted {
  background: #ecc870;

  color: #232a2c;
  border: 1px solid #ecc870;
  border-radius: 7px;
}

.pricing-card-wrapper {
  display: flex;
  flex-direction: column;
}

.pricecard {
  display: flex;
}


.col-3 .pricing-card-wrapper {
  flex: 1;
}

li {
  list-style: none;
}


.input-fieldblack {
  border: 1px solid #1d2f40;
}

.input-fieldblack::placeholder {
  color: #ffffff;
}

.input-login {
  background: #ecc870;
}

.input-login::placeholder {
  color: #232a2c;
}

.paymentimg {
  background-color: #ffffff;
  box-shadow: 0px 0px 3.999999761581421px 0px #3333330a;
}

.Checkout {
  background: #2a3a4a;
  box-shadow: 0px 0px 40px 0px #5e5c9a1f;
}

.btn-Choose {
  border: 2px solid #ffffff;
}

.light-mode .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color) !important;
  background-color: #2c3e50 !important;
  font-size: 14px !important;
}

.dark-mode .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color) !important;
  background-color: #ffc107 !important;
  font-size: 14px !important;
}

.light-mode .nav-pills .nav-link {
  color: black !important;
  background-color: #2c3e5000 !important;
  font-size: 14px !important;
}

.dark-mode .nav-pills .nav-link {
  color: #ffc107 !important;
  background-color: #2c3e5000 !important;
  font-size: 14px !important;
}

.light-mode .Recommended {
  background: #3498db;
  margin-top: -10px;
}

.dark-mode .Recommended {
  background: #ecc870;
  margin-top: -10px;
}

.Includes {
  border-bottom: 1px solid #ecc870;
  width: 214px;
}

.light-mode .form-check-input:checked[type="checkbox"] {
  background-color: #3498db !important;
}
.dark-mode .form-check-input:checked[type="checkbox"] {
  background-color: #e2b912 !important;
}

.form-control:focus {
  border-color: #e2b912 !important;
  outline: 0;
  box-shadow: none !important;
}

/* about us start */
.light-mode .aboutuspage {
  background: linear-gradient(270.3deg, #3498db 0.24%, #2c3e50 99.74%);
}

.dark-mode .aboutuspage {
  background: linear-gradient(270.3deg, #1d2f40 0.24%, #2c3e50 99.74%);
}

.light-mode .aboutusbtn {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: #ffffff;
}

.dark-mode .aboutusbtn {
  background-color: #ecc870;
  border: 1px solid #ecc870;
  color: #000;
}

.light-mode .aboutusbtn2 {
  background: #2c3e50;
  color: white;

  border: 1px solid #0d1824;
}

.aboutusbtn2 {
  background-color: transparent;
  border: 1px solid #ecc870;
  color: white;
}

.arrowline {
  background-color: #0d1824;
  color: white;
}

.light-mode .arrowline1 {
  background-color: #0d1824;
  color: #ffffff;
}

.dark-mode .arrowline1 {
  background-color: #ecc870;
  color: #0d1824;
}

.light-mode .aboutuscard {
  background: white;
}

.dark-mode .aboutuscard {
  background: #1d2f40;
}

.ourbackround {
  background-image: url(assetr/angl.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
}

.light-mode .bluecolr {
  color: #3498db;
}
.dark-mode .bluecolr {
  color: #e2b912;
}

/* Slick slider  */
.favourit-slider .slick-slide {
  margin-bottom: 20px;
  padding: 20px 5px;

}

.favourit-slider .slick-slide > div {
  margin: 0 14px;
}

.favourit-slider .slick-slide .whitcard {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
}

.light-mode .favourit-slider .slick-next:before {
  opacity: 3;
  color: white !important;
  background-color: #3498db !important;
  padding: 6px 10px !important;
  border-radius: 10%;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
}

.favourit-slider .slick-next:before {
  opacity: 3;
  color: #333333 !important;
  background-color: #e2b912 !important;
  padding: 6px 10px !important;
  border-radius: 10%;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
}

.light-mode .favourit-slider .slick-prev:before {
  opacity: 3;
  color: white !important;
  background-color: #3498db !important;
  padding: 6px 10px !important;
  border-radius: 10%;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
}

.dark-mode .favourit-slider .slick-prev:before {
  opacity: 3;
  color: #333333 !important;
  background-color: #e2b912 !important;
  padding: 6px 10px !important;
  border-radius: 10%;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
}

.favourit-slider .slick-prev {
  left: -30px !important;
  z-index: 12;
  top: 55% !important;
}

.favourit-slider .slick-prev:before {
  content: "\f060" !important;
  font-family: "fontawesome" !important;
}

.favourit-slider .slick-next {
  right: -14px !important;
  top: 55% !important;
}

.favourit-slider .slick-next:before {
  content: "\f061" !important;
  font-family: "fontawesome" !important;
}

.bg-Testimonials {
  background: #1d2f40;
}

.img-Checklinr {
  top: -6px;
  left: -60px;
}

.light-mode .slick-slider {
  background-color: #fff !important;
  color: black !important;
}

/* Contact Us */
.light-mode .bgimgContactUs {
  background-image: url(assetr/Untitled.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.bgimgContactUs {
  background-image: url(assetr/contactimg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.light-mode .mycardcolor {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
}

.dark-mode .mycardcolor {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    );
}

.light-mode .bg-questions {
  background: #ffffff;
  color: black;
}

.dark-mode .bg-questions {
  background: #1d2f40;
  color: #ffffff;
}

.light-mode .credit-lines {
  color: black;
}

.dark-mode .credit-lines {
  color: #ffffff;
}

.light-mode .credit-lines:before {
  background-color: black;
  content: "";
  display: inline-block;
  height: 1px;
  width: -webkit-fill-available;
}

.dark-mode .credit-lines:before {
  background-color: #ffffff;
  content: "";
  display: inline-block;
  height: 1px;
  width: -webkit-fill-available;
}

.light-mode .credit-lines:after {
  background-color: black;
  content: "";
  display: inline-block;
  height: 1px;
  width: -webkit-fill-available;
}

.dark-mode .credit-lines:after {
  background-color: #ffffff;
  content: "";
  display: inline-block;
  height: 1px;
  width: -webkit-fill-available;
}

.light-mode .coloryellow {
  color: black;
}

.dark-mode .coloryellow {
  color: #e2b912;
}

.min-100vh {
  min-height: 100vh !important;
}

.backgroundimges {
  background-image: url(assetr/loginpic.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.light-mode .btn-login {
  background: #3498db;
  color: #ffffff;
  border-color: #3498db;
}

.dark-mode .btn-login {
  background: #ecc870;
  color: #232a2c;
  border-color: #ecc870;
}

.light-mode .signupwithgoogle {
  background: #3498db1a;

  color: #3498db;
  border-color: #3498db;
}

.dark-mode .signupwithgoogle {
  background: transparent;
  color: #f5f5f5;
  border-color: #ecc870;
}

.light-mode .bgSUCCESS {
  background: #ffffff;
  box-shadow: 0px 0px 40px 0px #3498db1a;
}
.dark-mode .bgSUCCESS {
  background: #2a3a4a;
  box-shadow: 0px 0px 40px 0px #3498db1a;
}

/* scroll */
/* Tabs.css */
.tabs-container {
  overflow-x: auto;
  white-space: nowrap;
}

/* WebKit scrollbar customization */

.tabs-container::-webkit-scrollbar {
  width: 5px !important;

}

.light-mode .tabs-container::-webkit-scrollbar-track {
  background: #f1f1f1;

}

.dark-mode .tabs-container::-webkit-scrollbar-track {
  background: #000000;

}

.light-mode .tabs-container::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color, #a39d9d);
  border-radius: 10px;

}

.dark-mode .tabs-container::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color, #e9e5e5);
  border-radius: 10px;

}

/* end */

/* FAQs CSS */
.accordion {
  --bs-accordion-color: #1d2f40 !important;
  --bs-accordion-bg: #1d2f40 !important;
  --bs-accordion-active-bg: #1d2f40 !important;
  --bs-accordion-active-color: #ffffff !important;
  --bs-accordion-border-color: #1d2f40 !important;
}

.light-mode code {
  color: #3498db !important;
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

.dark-mode code {
  color: #e2b912 !important;
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

.light-mode .accordion-body {
  background-color: #fff !important;
}

.light-mode .accordion-button {
  color: black;
  background-color: #fff;
  box-shadow: 0px 0px 40px 0px #5e5c9a1f;
}

.dark-mode .accordion-button {
  color: #ffffff;

  box-shadow: 0px 0px 40px 0px #5e5c9a1f;
}

.accordion-button:focus {
  box-shadow: 0px 0px 40px 0px #5e5c9a1f !important;
}

.accordion-button::after {
  display: none !important;
}

.light-mode .accordion-button::before {
  font-family: "Arial";
  content: "+";
  margin-right: 10px;
  float: right;
  background: linear-gradient(270.3deg, #3498db 0.24%, #2c3e50 99.74%);
  box-shadow: 10px 0px 40px 0px #3498db33;
  border-radius: 5px;
  color: white;
  font-size: 15px;
  padding: 2px 4px;
  width: 20px;
  text-align: center;
}

.accordion-button::before {
  font-family: "Arial";
  content: "+";
  margin-right: 10px;
  float: right;
  background: linear-gradient(270.3deg, #ffde59 0.24%, #ff914d 99.74%);
  box-shadow: 10px 0px 40px 0px #3498db33;
  border-radius: 5px;
  color: #0d1824;
  font-size: 15px;
  padding: 2px 4px;
  width: 20px;
  text-align: center;
}

.accordion-button[aria-expanded="true"]::before {
  content: "-";
}

.accordion-button::after {
  font-family: "Arial" !important;
  content: "+" !important;
  float: right !important;
}

.accordion-button[aria-expanded="true"]::after {
  content: "-" !important;
  font-size: 15px;
}

.light-mode .tags {
  font-size: 12px;
  color: black;
}

.dark-mode .tags {
  font-size: 12px;
  color: white;
}

/* Profile Css start */

.light-mode .profilepage {
  background: linear-gradient(270.3deg, #3498db 0.24%, #2c3e50 99.74%);
}

.dark-mode .profilepage {
  background: linear-gradient(270.3deg, #ffde59 0.24%, #0d1824 99.74%);
}

.profilecontent {
  transform: translate(-1px, -74px);
  color: #ffffff;
  font-size: 30px;
}

.light-mode .profileinfo1 {
  color: black;
  box-shadow: 0px 0px 40px 0px #5e5c9a1f;
  background: #fff;
}

.dark-mode .profileinfo1 {
  color: #ffffff;
  box-shadow: 0px 0px 40px 0px #5e5c9a1f;
  background: linear-gradient(0deg, #0d1824, #0d1824),
    linear-gradient(90deg, #10181f 0.82%, #3498db 48.01%, #10181f 98.79%);
}
.light-mode .profileinfo {
  color: black;
  box-shadow: 0px 0px 40px 0px #5e5c9a1f;
  background: #fff;
}

.dark-mode .profileinfo {
  color: #ffffff;
  box-shadow: 0px 0px 40px 0px #5e5c9a1f;
  background: #2a3a4a;
}

.profilecard {
  box-shadow: 0px 0px 40px 0px #5e5c9a1f;
  background: linear-gradient(270.3deg, #1d2f40 0.24%, #2c3e50 99.74%);
}

.avataricon {
  background: #252c30;
  color: #e2b912;
  border-radius: 21px;
  width: 100px;
  height: 100px;
  font-size: 20px;
  border-radius: 50%;
}

.profile-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image img {
  width: 100px;
  height: 88px;
  border-radius: 50%;
}

#update-profile-input {
  display: none;
}

.custom-input {
  max-width: 100%;
}

.light-mode .custom-input {
  background-color: transparent;
  border: 1px solid #0000001a !important;
  border-radius: 4px;
  border-width: 2px;
  padding: 8px;
  color: black;
  transition: border-color 0.3s ease;
  width: 380px;
  outline: none;
  
}

.dark-mode .custom-input {
  background-color: transparent;
  border-color: #ecc870;
  border-radius: 4px;
  border-width: 2px;
  padding: 8px;
  color: white;
  transition: border-color 0.3s ease;
  width: 380px;
  outline: none;
  
}

.custom-input:hover {
  border: 1px solid #ecc870;
}

.custom-textarea {
  max-width: 100%;
}

.light-mode .custom-textarea {
  background: #fff;

  color: black;
  border-radius: 5px;
  border: 1px solid #0000001a !important;
  padding: 8px;
  transition: color 0.3s, border-color 0.3s;
  width: 780px;
}

.dark-mode .custom-textarea {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    linear-gradient(0deg, #232a2c, #232a2c);

  color: white;
  border-radius: 5px;
  border: 1px solid #0000001a;
  padding: 8px;
  transition: color 0.3s, border-color 0.3s;
  width: 780px;
}

.light-mode .custom-textarea:hover {
  color: black;
}

.dark-mode .custom-textarea:hover {
  color: #ffffff;
}

.light-mode .custom-select {
  background-color: transparent;
  border: 1px solid #0000001a !important;
  border-radius: 4px;
  border-width: 2px;
  padding: 8px;
  color: white;
  transition: border-color 0.3s ease;
  width: 380px;
  outline: none;

}

.dark-mode .custom-select {
  background-color: transparent;
  border-color: #ecc870;
  border-radius: 4px;
  border-width: 2px;
  padding: 8px;
  color: white;
  transition: border-color 0.3s ease;
  width: 380px;
  outline: none;
  
}

.light-mode .custom-select:hover {
  border: 1px solid #ecc870;
}

.custom-select:hover {
  border: 1px solid #ecc870;
}

.custom-input1 {
  max-width: 100%;
}

.light-mode .custom-input1 {
  background-color: transparent;
  border: 1px solid #0000001a !important;
  border-radius: 4px;
  border-width: 2px;
  padding: 8px;
  color: white;
  transition: border-color 0.3s ease;
  width: 780px;
  outline: none;
}

.light-mode .starcolor {
  color: #3498db;
}

.dark-mode .starcolor {
  color: #e2b912;
}

.dark-mode .custom-input1 {
  background-color: transparent;
  border-color: #ecc870;
  border-radius: 4px;
  border-width: 2px;
  padding: 8px;
  color: white;
  transition: border-color 0.3s ease;
  width: 780px;
  outline: none;
}

.custom-input1:hover {
  border: 1px solid #ecc870;
}

.light-mode .notificationcart {
  background: #fff;
}

.dark-mode .notificationcart {
  background: #232a2c;
}

.light-mode .membershipcard {
  box-shadow: 0px -11px 30px 0px #3498db1a;
  background: #fff;
}

.membershipcard {
  box-shadow: 0px -11px 30px 0px #3498db1a;
  background: #2a3a4a;
}

.light-mode .membershinlein {
  border-bottom: 1px solid #000000;
}
.dark-mode .membershinlein {
  border-bottom: 1px solid #ffffff;
}

.light-mode .membershipbtn {
  background: linear-gradient(
    138.93deg,
    #f22876 0%,
    #2c3e50 0.01%,
    #3498db 100%
  );

  box-shadow: 0.87px 9.96px 20px 0px #3498db4d;

  border: 1px solid #3498db;
  color: white;
  position: relative;
  z-index: 999;
}

.dark-mode .membershipbtn {
  background: linear-gradient(270.3deg, #ff914d 0.24%, #ffde59 99.74%);
  box-shadow: 10px 0px 40px 0px #3498db33;
  border: 1px solid #ecc870;
  color: #0d1824;
  position: relative;
  z-index: 999;
}

.membersjipbgimg {
  left: 0;
  width: 261px;
  height: 52px;
}

/* Search Container */
.light-mode .search-container {
  width: 300px;
  border-radius: 8px;
}

.dark-mode .search-container {
  width: 300px;
  border-radius: 8px;
}

/* Search Input */
.light-mode .search-container input[type="text"] {
  width: calc(100% - 40px);
  padding: 8px 10px;
  border: 1px solid black;
  border-radius: 10px;
  outline: none;
  background-color: transparent;
  color: #fffbfb;
}

.dark-mode .search-container input[type="text"] {
  width: calc(100% - 40px);
  padding: 8px 10px;
  border: 1px solid rgb(255, 250, 250);
  border-radius: 10px;
  outline: none;
  background-color: transparent;
  color: #fffbfb;
}

.searchicon {
  background: #262c31;
  font-size: 12px;
  color: #ffffff;
  padding: 8px !important;
  cursor: pointer;
  border-radius: 8px;
}


.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.light-mode .custom-table th {
  background-color: #3498db26;
  color: #000;
  padding: 8px;
}

.dark-mode .custom-table th {
  background-color: #262d32;
  color: #fff;
  padding: 8px;
}

.custom-table td {
  padding: 8px;
}

.delete-button {
  background-color: #f42e46;
  color: #ffffff;
  border: 1px solid #262d32;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.tableline {
  border-bottom: 1px solid #1d2f40;
}

.light-mode .custom-input-container {
  position: relative;
  display: inline-block;
  width: 100%;
  border: 1px solid #0000001a;
  border-radius: 5px;
  max-width: 200px;
}

.dark-mode .custom-input-container {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 200px;
}

.light-mode .custom-input3 {
  padding: 8px 30px 8px 10px;
  border: none;
  border-radius: 8px;
  outline: none;
  background-color: transparent;
  color: #000000;
  transition: color 0.3s, border-color 0.3s;
  border: 1px solid #ffffff;
  width: 162px;
}

.dark-mode .custom-input3 {
  padding: 8px 30px 8px 10px;
  border: none;
  border-radius: 8px;
  outline: none;
  background-color: transparent;
  color: #ffffff;
  transition: color 0.3s, border-color 0.3s;
  border: 1px solid #ffffff;
  width: 162px;
}

.custom-input3::placeholder {
  color: #aaa;
}

.custom-input3.hovered {
  border-color: yellow;

}

/* Calendar Icon */
.calendar-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #fff;
  cursor: pointer;
}

.calendar-icon1 {
  position: absolute;
  top: 50%;
  right: 23px;
  transform: translateY(-50%);
  color: #fff;
    cursor: pointer;
}

.custom-inputdetail {
  background: #232a2c;

  border: 1px solid #232a2c;
  border-radius: 4px;
  border-width: 2px;
  padding: 8px;
  color: white;
  transition: border-color 0.3s ease;
  width: 325px;
  outline: none;
}

.custom-inputdetail:hover {
  border: 1px solid #ecc870;
}

.custom-inputdetail1 {
  background: #232a2c;

  border: 1px solid #232a2c;
  border-radius: 4px;
  border-width: 2px;
  padding: 8px;
  color: white;
  transition: border-color 0.3s ease;
  width: 159px;
  outline: none;
}

.custom-inputdetail1:hover {
  border: 1px solid #ecc870;
}

.light-mode .plusicon {
  right: 0;
  background: linear-gradient(166deg, #2c3e50 0%, #3498db 100%);

  padding: 0px 6px;
  border-radius: 20px;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark-mode .plusicon {
  right: 0;
  background: linear-gradient(166deg, #ffde59 0%, #ff914d 100%);
  padding: 0px 6px;
  border-radius: 20px;
  font-size: 18px;
  color: #000;
  cursor: pointer;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-container {
  position: relative;
}

.light-mode .filter-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: black;
}

.dark-mode .filter-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: white;
}

.light-mode .form-select {
  background: transparent;
  border: 1px solid #0000001a !important;
  color: black;
}

.dark-mode .form-select {
  background: #2a3a4a;
  color: white;
}

.iconshover {
  border-radius: 50%;
  padding: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.iconshover:hover {
  color: yellow;
}

.light-mode .active-page {
  background-color: #3498db !important;
}

.dark-mode .active-page {
  background-color: #ecc870 !important;
}

.light-mode .page-link {
  position: relative;
  border-radius: 7px !important;
  color: black !important;
  text-decoration: none;
  border: 1px solid #f5f5f9 !important;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dark-mode .page-link {
  position: relative;
  border-radius: 7px !important;
  color: #ffffff !important;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid #ecc870 !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.light-mode .page-link:hover {
  z-index: 2;
  color: #000 !important;
  background-color: #3498db !important;
  border-color: 1px solid #3498db !important;
}

.dark-mode .page-link:hover {
  z-index: 2;
  color: #ffffff !important;
  background-color: #ecc870;
  border-color: 1px solid #ecc870 !important;
}

.checkout-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout-button svg {
  margin-left: 5px;
}

/* end of pegination */

/* slick slider css */
.sliderimagess {
  border: 1px solid #ecc870;
  background-color: #10181f;
  height: 370px;
}

.imgSlider .custom-thumbs-container .thumb {
  margin-top: 10px;
  cursor: pointer;
  width: 40px;
  height: 52px;
  border: 1px solid #ecc870;
  background-color: #10181f;
}

.cardcontent3 {
  position: absolute;
  bottom: 0px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.playicon {
  position: absolute;
  top: 150px;
  left: 100px;
}

.backgroundgray {
  background-color: #e6e9ed !important;
}

.imgSlider .carousel.carousel-slider {
  overflow: initial !important;
}

.imgSlider .vertical-carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgSlider .vertical-slide img {
  max-height: 100%;
  max-width: 100%;
}

.imgSlider .carousel-root.vertical-carousel {
  margin-left: 10px;
}

.imgSlider .custom-thumbs-container {
  width: 55%;
}

.imgSlider .custom-thumbs-container img {
  width: 87%;
  height: 44px;
}

.imgSlider .custom-thumbs-container .thumb {
  margin-top: 10px;
  cursor: pointer;
  width: 40px;
  height: 52px;
}

.imgSlider .control-arrow {
  top: 50% !important;
  color: #fff;
  opacity: 1 !important;
  height: 40px !important;
  width: 40px !important;
  background: none !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgSlider .control-arrow:before {
  margin: 0 5px;
  font-size: 16px !important;
  display: inline-block;
  border-top: none !important;
  border-bottom: none !important;
  content: "2" !important;
  border-right: none !important;
}

.imgSlider .control-prev.control-arrow {
  left: -12px !important;
}

.imgSlider .control-next.control-arrow {
  right: -12px !important;
}

.imgSlider .control-prev.control-arrow:before {
  border-left: none !important;
  content: "" !important;
  font-family: "fontawesome" !important;
}

.imgSlider .control-next.control-arrow:before {
  border-left: none !important;
  content: "" !important;
  font-family: "fontawesome" !important;
}

.imgSlider .control-next.control-arrow .gigs-slider .slick-prev:before {
  content: "\f104" !important;
  font-family: "fontawesome" !important;
}

.imgSlider .slide {
  width: 100% !important;
  height: 432px !important;
  padding-left: 6px !important;
  border-radius: 10px !important;
}

.imgSlider .slide > div {
  height: 78%;
  border-radius: 10px !important;
}

.imgSlider .slide > div img {
  height: 100%;
  border-radius: 10px !important;
}

.imgSlider .slide.selected {
  padding-left: 0px !important;
  border-radius: 10px !important;
}

.imgSlider .thumbs-wrapper {
  margin-inline: 0px !important;
}

.imgSlider .thumbs-wrapper .control-prev {
  display: none !important;
}

.imgSlider .thumbs-wrapper ul {
  padding-left: 0 !important;
  overflow-x: scroll !important;
  transform: translate3d(0px, 0px, 0px) !important;
}

.imgSlider .thumbs-wrapper ul::-webkit-scrollbar {
  display: none;
}

.imgSlider .thumbs-wrapper .thumb {
  border-radius: 8px;
  height: 90px !important;
  width: 130px !important;
  border-radius: 8px !important;
  background-color: white;
}

.imgSlider .thumbs-wrapper .thumb img {
  height: 100% !important;
  border-radius: 8px !important;
}

.imgSlider .thumbs-wrapper .thumbs {
  justify-content: space-between;
  display: flex;
}

.gigi-tabs .nav-pills li:first-child button {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.gigi-tabs .nav-pills li:last-child button {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 12px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.gigi-tabs .nav-pills li:nth-child(2) button {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.gigi-tabs .nav-pills .nav-link {
  color: #1d2b3c !important;
  background-color: #e2e2e2 !important;
}

.gigi-tabs .nav-pills .nav-link.active {
  color: white !important;
  background-color: #f16336 !important;
}

.gig-rating-rewies .progress {
  border-radius: 2px !important;
  background-color: #d9d9d9 !important;
}

.gig-rating-rewies .progress .progress-bar {
  border-radius: 2px !important;
  background-color: #f16336 !important;
}

.gigs-slider .gigs-slider-bg {
  width: 100%;
  border-radius: 12px;
  height: calc(100% - 48%);
  background: #f5f5ff;
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
}

.gigs-slider .slick-slide {
  margin-bottom: 20px;
  padding: 20px 5px !important;
}

.gigs-slider .slick-slide > div {
  margin: 0 14px;
}

.gigs-slider .slick-slide .carousel .control-dots .dot {
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
}

.gigs-slider .slick-slide .whitcard {
  box-shadow: 0px 0px 20px 0px #000 1A !important;
}

.gigs-slider .slick-next:before {
  opacity: 3;
  color: wheat !important;
  background-color: #f16336;
  padding: 6px 10px !important;
  border-radius: 50%;
  box-shadow: -4px 4px 20px rgba(32, 180, 134, 0.12);
}

.gigs-slider .slick-prev:before {
  opacity: 3;
  color: wheat !important;
  background-color: #f16336;
  padding: 6px 10px !important;
  border-radius: 50%;
  box-shadow: -4px 4px 20px rgba(32, 180, 134, 0.12);
}

.gigs-slider .slick-prev {
  left: 0px !important;
  z-index: 12;
  top: 45% !important;
}

.gigs-slider .slick-prev:before {
  content: "\f104" !important;
  font-family: "fontawesome" !important;
}

.gigs-slider .slick-next {
  right: 18px !important;
  top: 45% !important;
}

.gigs-slider .slick-next:before {
  font-family: "fontawesome" !important;
  content: "\f105" !important;
}

.bordertop {
  border-bottom: 1px solid #aaa;
}

.bordertop1 {
  border-top: 1px solid #aaa;
}

.modal-body {
  padding: 0;
  border: 1px solid white;
  background: black;
}

.modal-body a {
  text-decoration: none;
  color: white;
}

.modal-body .shimmer-card {
  margin: 0;
}

.modal-body .coloryellow {
  color: #e2b912;
  margin-left: 8px;
}

.modal-body .republictextwhite {
  color: white;
  font-weight: 700;
}

.modal-body .tags {
  font-size: small;
  font-weight: 700;
}

.modal-body .icon-container {
  width: 40px;
  height: 40px;
  background: linear-gradient(0deg, #1d2f40, #1d2f40),
    linear-gradient(0deg, rgba(33, 37, 41, 0.09), rgba(33, 37, 41, 0.09));
  border: 1px solid #21252917;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ecc870;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

@media (max-width: 576px) {
  .filter-icon {
    position: absolute;
    top: 50%;
    left: 201px;
    right: 10px;
    transform: translateY(-50%);
    color: black;
  }

  .form-select {
    width: 238px;
  }

  .imgSlider {
    .slide {
      height: 421px !important;
    }
  }
}


@media (max-width: 320px) {
  .membersjipbgimg {
    width: 295px !important;
}
}

/* For mobile screens */
@media (max-width: 576px) {

  .col-4 {
    width: 100%;
   
  }

  .dragoncard2 {
  
    padding: 10px;
    
  }

  .bg-questions {
    transform: translate(0px, 0px);
  }

  .form-check-label {
    font-size: 8px;
  }

  .related-video {
    right: 122px;
  }

  .custom-textarea {
    width: 317px;
  }

  .custom-input {
    width: 315px;
  }

  .membersjipbgimg {
    width: 364px;
  }

  .custom-input1 {
    width: 312px;
  }

  .custom-select {
    max-width: 308px;
  }

  .calendar-icon {
    right: 24px;
  }

  .bg-maineimg {
    min-height: 32vh !important;
    height: 0vh !important;
  }
}

/* For larger screens */
@media (min-width: 768px) {
  
  .col-8 {
   
    width: 66.66%;
  }

  .col-4 {
    
    width: 33.33%;
  }

}

/* For smaller screens */
@media (max-width: 767px) {
  .col-8,
  .col-4 {
    width: 100%;
  }
}

/* Example media query for adjusting styles for smaller devices */
@media (max-width: 768px) {

  .font-70 {
    font-size: 50px;

  }
  .filtericons {
  
    right: 125px;
  }
  .card {
    transform: translate(1px, 0px);
}
  .custom-input3 {
    width: 100%;
  }

  .calendar-icon {
    right: 10px;
  }

 

  .font-75 {
    font-size: 50px;
  }
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
}

.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 65px solid #2fd9c4;
  border-color: #2fd9c4 transparent #2fd9c4 transparent;
  animation: lds-hourglass 1.2s infinite;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  100% {
    transform: rotate(1800deg);
  }
}

@media (max-width: 500px) {
  .overflowXScroll {
    overflow-x: scroll;
  }

  .video-card-wrapper {
    overflow: hidden;
  
  }

  .video-card {
    transition: transform 0.3s ease;

  }

  .video-card:hover {
    transform: scale(1.1);

  }

  .font-50 {
    font-size: 40px;
  }

  .font-40 {
    font-size: 30px;
  }

  .font-75 {
    font-size: 40px;
  }
}

.shimmer-card {
  width: 100%;
  margin: 10px auto;
  padding: 1px;
  transition: transform 0.3s ease;
  position: relative;
  z-index: 1;

}

@media (min-width: 720px) {
  .light-mode .shimmer-card:hover {
    background-color: #fff;
  }
  .dark-mode .shimmer-card:hover {
    background-color: #000;
  }
  .shimmer-card:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: transform 1s ease;
    transform: scale(1.1);
    position: absolute;
    z-index: 2;
    padding: 5px;
  }
}

.video-tags {
  display: none;
}

.video-tags p {
  margin: 5px;
}

.shimmer-card:hover .video-tags {
  display: flex;
}

.video-box-shadow {
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.shimmer-animation {
  background-color: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #ffefb0 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 100%;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

.loader-shimmer-banner {
  height: 168px;
  background-size: 800px 18rem;
  border-radius: 5px;
  display: flex;
}

@media (min-width: 1600px) {
  .loader-shimmer-banner {
    height: 330px;
  }
}

@media (max-width: 500px) {
  .shimmer-card {
    width: 100%;
  }

  .loader-shimmer-banner {
    height: auto;
  }
}

.loader-shimmer-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.loader-shimmer-title {
  width: 80%;
}

.light-mode .loader-shimmer-title {
  flex: 0 0 auto;
  color: black;
}

.loader-shimmer-title {
  flex: 0 0 auto;
  font-weight: 700;
  font-size: 12px;
}

.loader-shimmer-rating {
  flex: 0 0 auto;
}

.loader-shimmer-list {
  font-weight: 400;
  font-size: 13px;
}

.light-mode .loader-shimmer-list {
  margin-bottom: 5px;
  font-weight: 400;
  color: black;
}

.light-mode .loader-shimmer-list {
  margin-bottom: 5px;
  font-weight: 400;
}

.loader-shimmer-list:last-child {
  margin-bottom: 0;
}

.loader-shimmer-info {
  margin-bottom: 5px;
}

.loader-shimmer-content {
  height: auto;
}

.light-mode .loader-shimmer-info:last-child {
  margin-bottom: 0;
  color: black;
}

.loader-shimmer-info:last-child {
  margin-bottom: 0;
}

.play-box {
  text-align: left;
}

.video-play-icon {
}

.dark-mode .favourite {
  background: #e2b912;
}

.dark-mode .favourite svg {
  border-radius: 50%;
  color: black;
  background: #e2b912;
  transform: scale(1.3);
  transition: 0.4s;
}

.light-mode .favourite {
  background: #3498db;
}

.light-mode .favourite svg {
  border-radius: 50%;
  color: black;
  background: #3498db;
  transform: scale(1.3);
  transition: 0.4s;
}

.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}


.loading:not(:required) {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
